import React from 'react'
import { animateScroll as scroll } from 'react-scroll';
import{FaFacebook,FaInstagram,FaYoutube, FaTwitter,FaLinkedin} from 'react-icons/fa';
import { FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap,
    SocialLogo, WebsiteRights, SocialIcons, SocialIconsLink} from './FooterElements'

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };  
  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        Study Centric
                    </SocialLogo>
                    <WebsiteRights>Study Centric © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconsLink aria-label="Facebook">
                            <FaFacebook />
                        </SocialIconsLink>
                        <SocialIconsLink aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconsLink>
                        <SocialIconsLink aria-label="YouTube">
                            <FaYoutube />
                        </SocialIconsLink>
                        <SocialIconsLink aria-label="Twitter">
                            <FaTwitter />
                        </SocialIconsLink>
                        <SocialIconsLink aria-label="LinkedIn">
                            <FaLinkedin />
                        </SocialIconsLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
