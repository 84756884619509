import React from 'react'
import emailjs from 'emailjs-com'
import {Container, Form, FormButton, FormContent, FormH1, FormHome, FormInput, FormLabel, FormMessage, FormWrap, Icon } from './SigninElements'

const SignIn = ({submitForm}) => {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7ve3qe3', 'template_bokh865', e.target, 'C_gU9TBc54VAWCzTu')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      submitForm();
    };

    
  return (
    <>
      <Container>
        <FormWrap>
            <Icon to="/">Study Centric</Icon>
            <FormContent>
                <Form onSubmit={sendEmail}>
                    <FormH1>Contact Us!</FormH1>
                    <FormLabel htmlFor='for'>Name</FormLabel>
                    <FormInput type='text' placeholder='Name' name='name' required />
                    <FormLabel htmlFor='for'>Email</FormLabel>
                    <FormInput type='email' placeholder='Email' name='email' required />
                    <FormLabel htmlFor='for'>Phone Number</FormLabel>
                    <FormInput type='tel' placeholder='xxx-xxx-xxxx' name='number' required />
                    <FormLabel htmlFor='for'>Message</FormLabel>
                    <FormMessage type='text' name='subject' required />
                    <FormButton type='submit' value='Send'>Send</FormButton>
                    <FormHome to="/">Home</FormHome>
                </Form>
            </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default SignIn