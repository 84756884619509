import React, {useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll'
import {Nav, NavbarContainer, NavLogo, LogoImg, MobileIcon, NavMenu, NavItem, NavLinks,
         NavBtnLink} from './NavbarElements';  
import logo from '../../images/study-centric-logo.png'             

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}} >
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}>
                    <LogoImg src={logo}/>
                </NavLogo>
                <MobileIcon onClick={toggle}>
                  <FaBars/>
                </MobileIcon>
              <NavMenu>
                  <NavItem>
                      <NavLinks to="about" 
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                      >About Me</NavLinks>
                  </NavItem>
                  <NavItem>
                      <NavLinks to="course"
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                      >Course</NavLinks>
                  </NavItem>
                  <NavItem>
                      <NavLinks to="services"
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                      >Private Tutoring</NavLinks>
                  </NavItem>
                  <NavBtnLink to="/signin">Contact</NavBtnLink>
              </NavMenu>
            </NavbarContainer>
        </Nav>
    </IconContext.Provider>   
    </>
  )
}

export default Navbar