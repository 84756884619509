import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Form from '../components/Form'

const SigninPage = () => {
  return (
    <>
        <ScrollToTop />
        <Form />
    </>
  )
}

export default SigninPage