import styled from 'styled-components';
import ImgBg from '../../images/office-supplies-on-white-background.jpg'
import { colorSec } from '../../globalStyles';

export const AboutSection = styled.div`
    height: auto;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    background: #B5C6C4;
    padding: 30px;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }
`
// max-width: 1000px;
//    top: -40px;
//     max-height: 100vh;
export const AboutContainer = styled.div`
    width: 100%;

    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
        flex-direction: column;
    }
`

export const AboutLeft = styled.div`

    z-index: 1;

    width: 60%;
    min-height: 400px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    color: #000;


    @media screen and (max-width: 768px) {
        width: 85%;
    }
`
//    margin-left: -100px;
export const AboutRight = styled.div`

    z-index: 0;

    width: 40%;
    height: 600px;
    background: url(${ImgBg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;


    @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: -500px;
        margin-left: 0px;
    }
`
//     max-width: 540px;
export const TextWrapper = styled.div`

    padding-top: 0;
    padding-bottom: 20px;

`

export const TopLine = styled.p`
    color: ${colorSec};
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    max-width: 540px;
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #010606;

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`
//     max-width: 1000px; 
export const Subtitle = styled.p`
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 24px;
    color: #010606;
`

export const ListHeading = styled.h2`
    max-width: 880px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    color: #010606;
`

export const List = styled.li`
    list-style: inside;
    max-width: 880px;
    font-size: 18px;
    line-height: 24px;
    color: #010606;
`