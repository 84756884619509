import React from 'react';
import Icon1 from '../../images/child-with-abbacus.jpg'
import Icon2 from '../../images/astronaut-in-space.jpg'
import Icon3 from '../../images/world-map-on-blackboard.jpg'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';

const Services = () => {
  return (
    <>
        <ServicesContainer id="services">
            <ServicesH1>Private Tutoring</ServicesH1>
            <ServicesWrapper to="/signin">
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Mathmatics</ServicesH2>
                    <ServicesP>Algebra, Calculus I, II, and III, Trigonometry, Geometry.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Science</ServicesH2>
                    <ServicesP>Chemistry, Biology, Physics, Organic Chemistry.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>Miscellaneous</ServicesH2>
                    <ServicesP>History, Psychology, Languages, Geography.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    </>
  )
}

export default Services