import styled from 'styled-components';
import ImgBg from '../../images/about-me-photo.jpg'

export const AboutSection = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    background: #f9f9f9;
`

export const AboutContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;


    @media screen and (max-width: 768px) {
        padding: 100px 0;
        flex-direction: column;
    }
`

export const AboutLeft = styled.div`
    width: 50%;
    height: 600px;
    background: url(${ImgBg});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    border-radius: 8px;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`

export const AboutRight = styled.div`
    width: 50%;
    min-height: 400px;
    background: #DAE3E2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    color: #000;
    margin-left: -30px;

    @media screen and (max-width: 768px) {
        width: 65%;
        margin-left: 0px;
        margin-top: -200px;
    }
`
export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 20px;

`

export const Topline = styled.p`
    color: #011638;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    line-height: 16px;
`
export const Paragraph = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
`