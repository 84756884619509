import React from 'react'
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroP} from './HeroElements'

// autoPlay loop muted src={Video} type='vide/mp4'

const HeroSection = () => {
  return (
    <HeroContainer id='home'>
        <HeroBg />
        <HeroContent>
            <HeroH1>Study Smarter</HeroH1>
            <HeroP>
            With our Advanced Academic Skills Course, we teach students how to study more efficiently - effectively reducing 
            study time while simultaneously improving their grades.

            </HeroP>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection