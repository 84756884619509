import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from "../components/Navbar";
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import Footer from '../components/Footer';
import AboutMeSection from '../components/About';
import CourseSection from '../components/Course';

const Home = () => {
    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AboutMeSection />
      <CourseSection />
      <Services/>
      <Footer />
    </>
  )
}

export default Home