import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const Container = styled.div`
    min-height: 100vh;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: auto;
    background: #011638;
`

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 400px) {
        height: 80%;
    }
`

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px) {
        margin-left: 30px;
        margin-top: 30px;
    }
`
export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`
//background: #000;
export const Form = styled.form`
    background: #fff;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
`

export const FormH1 = styled.h1`
    margin-top: 40px;
    color: #000;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`
export const VideoWrap = styled.div`
    background: #fff;
`

export const VideoIcon = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;
`

export const FormP = styled.p`
    color: #000;
    text-align: center;
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 10px;
`


export const HomeBtn = styled(Link)`
    border-radius: 50px;
    max-width: 120px;
    background: #011638;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-align: center;
    justify-self: center;

    margin-top: 10px;

    &:hover{
        transtition: all 0.2s ease-in-out;
        background: #fff;
        color: #000;
    }
`