// // Laurel & Grey
// export const colorNav = '#DAE3E2';
// export const colorNavTxt = '#101522';
// export const colorNavHover = '#101522';
// export const colorNavBtn = '92979B';
// export const colorHeroH1 = '#333745';
// export const colorHeroP = '#333745';
// export const colorSerBg = '#DAE3E2';
// export const colorServH1 = '#333745';
// export const colorPri = '#DAE3E2';
// export const colorSec = '#92979B';
// export const colorAcc = '#DAE3E2';
// export const colorBlk = '#101522';
// export const colorFtr = '#333745';

// Blue & Orange
export const colorNav = '#011638';
export const colorNavTxt = '#fff';
export const colorNavHover = '#101522';
export const colorNavBtn = '#fff';
export const colorHeroH1 = '#333745';
export const colorHeroP = '#333745';
export const colorSerBg = '#DAE3E2';
export const colorServH1 = '#333745';
export const colorPri = '#DAE3E2';
export const colorSec = '#92979B';
export const colorAcc = '#DAE3E2';
export const colorBlk = '#101522';
export const colorFtr = '#011028';

// color nav: 