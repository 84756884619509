import React, {useState} from 'react'
import Signin from '../Signin'
import Success from '../SuccessForm'

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);  

  function submitForm() {
      setIsSubmitted(true);
  }  

  return (
    <div>
        {!isSubmitted ? <Signin submitForm={submitForm} /> : <Success />}
    </div>
  )
}

export default Form