import React from 'react'
import { AboutContainer, AboutLeft, AboutRight, AboutSection, Paragraph, TextWrapper, Topline } from './AboutMeElements'

const AboutMeSection
 = () => {
  return (
    <>
        <AboutSection id='about'>
            <AboutContainer>
                <AboutLeft />
                <AboutRight>
                  <TextWrapper>
                    <Topline>About Me</Topline>
                    <Paragraph>After graduating magna cum laude with a BS in chemical engineering, I realized that I was uniquely qualified to help students become the most successful version of themselves. </Paragraph>
                    <Paragraph>I tutor math, science, engineering, programming, and various other subjects. In addition to helping students understand and retain new information, I focus on helping build time management and effective learning skills during each session. This ensures that students can eventually work independently. I have found that combining traditional tutoring with teaching soft skills creates well-rounded students.</Paragraph>
                  </TextWrapper>  
                </AboutRight>
            </AboutContainer>
        </AboutSection>
    </>
  )
}

export default AboutMeSection
