import React from 'react'
import {Container, Form, FormContent, FormH1, FormWrap, Icon, VideoIcon, FormP, HomeBtn, VideoWrap} from './SuccessElements'

const SuccessForm = () => {
  return (
    <>
      <Container>
        <FormWrap>

            <Icon to="/">Study Centric</Icon>
            <FormContent>
                <Form>
                    <VideoWrap>
                      <VideoIcon img src={require('../../videos/paper-plane.gif')} alt="paper plane" />
                    </VideoWrap>
                    <FormH1>Thank You!</FormH1>
                    <FormP>We'll be in contact with you very soon.</FormP>
                    <HomeBtn to="/">Home</HomeBtn>
                </Form>
            </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default SuccessForm