import React from 'react'
import { AboutContainer, AboutLeft, AboutRight, AboutSection, TextWrapper, TopLine, Heading, Subtitle, ListHeading, List } from './CourseElements'

const CourseSection
 = () => {
  return (
    <>
        <AboutSection id='course'>
            <AboutContainer>
                <AboutLeft>
                  <TextWrapper>
                  <TopLine>Study Centric</TopLine>
                <Heading>About the Course</Heading>
                <Subtitle>
                Academic success is not only predicated on a student’s ability, but also on how well a student utilizes proven study 
                methods and techniques. Most students fail to learn how to maximize their time.  Many students rely on reading 
                through material repeatedly in an attempt to retain information.  Some students take notes or make flashcards to 
                digest materials.  But these approaches fail to help students gain new understanding, knowledge, or skills.  
                Frankly, most students underperform because they are rarely taught HOW to study.  
                This leads to frustration and emotional stress.  When students learn to study smarter, they gain confidence to 
                help them excel in the classroom and on exams.  
                </Subtitle>
                <Subtitle>
                With the StudyCentric course, we teach our students how to recognize what needs to be memorized, understood, 
                and practiced.  We teach students how to discern what content they must master as well as memorization skills to 
                recall necessary data effectively and efficiently. We work closely with each student to customize the StudyCentric 
                course in order to personally address his/her specific needs to ensure the best results. By working in groups, 
                students are taught how to study while using the content of their school curriculum. Additionally, students are 
                coached to adjust their methods for different subjects to optimize time.
                </Subtitle>
                <ListHeading>The course covers the following topics:</ListHeading>
                <List>Organization and note-making mnemonics - how to use them efficiently.</List>
                <List>How to use text imagery for easy recall.</List>
                <List>Memorizing, summarizing, and self-explanation methods.</List>
                <List>Test taking tips and how to study for tests.</List> 
                  </TextWrapper> 
                </AboutLeft>
                <AboutRight /> 
            </AboutContainer>
        </AboutSection>
    </>
  )
}

export default CourseSection
