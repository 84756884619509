import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'
import { colorNav, colorNavHover, colorNavTxt } from '../../globalStyles';

// top nav bar 
export const Nav = styled.nav`
    background: ${colorNav};
    height: 80px; 
    margin-top: -80px;   
    display: flex;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`
// ?
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
`
// logo home link
export const NavLogo = styled(LinkR)`
    justify-self: flex-start;
    max-width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    object-fit: cover;
`
export const LogoImg = styled.img`
    width: 100%;
    height: 80%;

`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: ${colorNavTxt};
    }
`
// The little 3 bars for mobile menu 
//     margin-right: -22px;
//    display: flex;
// align-items: center;
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 22px;


    @media screen and (max-width: 768px){
        display: none;
    }
`
// nav "about, courses, ..." behavior
export const NavItem = styled.li`
    height: 80px;
`
// nav "about, courses, ..." styling
export const NavLinks = styled(LinkS)`
    color: ${colorNavTxt};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    position: relative;

    &.active {
        border-bottom: 3px solid ${colorNavHover};
    }

`
// green "sign in" btn behavior
export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-left: 30px;

    @media screen and (max-width: 768px){
        display: none;
    }

`
// "sign in" btn styling
export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: #fff;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    margin-left: 24px;

    &:hover{
        transtition: all 0.2s ease-in-out;
        background: #000;
        color: #fff;
    }
`
