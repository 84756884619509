import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom' 
import {FaTimes} from 'react-icons/fa'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #011638;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};   
`

// X icon 
export const CloseIcon = styled(FaTimes)`
    color: #fff
`

// X Icon Style
export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    outline: none;
`
// ???????????
export const SidebarWrapper = styled.div`
    color: #fff;
`
// Sidebar 'About, Discover, ...' Links Position
export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`
// Sidebar 'About, Discover, ...' Links Style
export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #6E8894;
        transition: 0.2s ease-in-out;
    }
`
// Sidebar Sign in Button Position
export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`

// Sidebar Sign in Button Style
export const SidebarRoute = styled(LinkR)`
    border-radius: 50px;
    background: #6E8894;
    white-space: nowrap;
    padding: 16px 64px;
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`